<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title class="headline" t-data="dialog-headline">
        <h4 class="header-text--text">
          Want to continue managing admin group?
        </h4>
      </v-card-title>
      <v-card-text t-data="dialog-content">
        <p class="body-regular primary-text-color--text mb-0">
          The Organization is created successfully. To complete the organization
          setup, you requires to assign an admin group into this organization,
          click “Continue” to proceed. Or else complete it later by clicking
          “Cancel”.
        </p>
      </v-card-text>
      <v-card-actions t-data="dialog-actions">
        <v-spacer></v-spacer>
        <v-btn
          id="alert-cancel"
          class="cancel-button button-bold"
          t-data="cancel-btn"
          color="primaryTextColor"
          text
          @click="clickCancel"
        >
          Cancel
        </v-btn>
        <v-btn
          id="alert-cancel"
          class="cancel-button button-bold"
          t-data="continue-btn"
          color="info600Color"
          text
          @click="clickContinue"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'AlertSaveUserSuccess',
  props: {
    show: {type: Boolean}
  },
  computed: {
    ...mapState('rolePermission', ['createdRole'])
  },
  methods: {
    clickCancel() {
      this.$router.push({
        name: 'organization-list',
        query: {isCreateSuccess: true, snackTitle: 'created'}
      })
    },
    clickContinue() {
      const createdRoleId = this.createdRole.id
      this.resetCreatedRoleState()
      this.$router.push({
        name: 'role-permission-edit',
        params: {id: createdRoleId}
      })
    },
    ...mapActions('rolePermission', ['resetCreatedRoleState'])
  }
}
</script>

<style scoped></style>
