<template>
  <v-container>
    <v-row class="mb-4">
      <v-col cols="6" t-data="info-input">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Branch <span class="danger500Color--text">*</span>
        </p>
        <v-text-field
          outlined
          required
          dense
          class="subtitle-2 can-disable-selector"
          :placeholder="disabledEdit ? '' : 'Hospital full name (e.g. Princ Suvarnabhumi Hospital)'"
          :rules="nameRules"
          v-model="nameVal"
          :error-messages="errorsOrg"
          :disabled="disabledEdit"
        ></v-text-field>
      </v-col>
      <v-col cols="6" t-data="info-input">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Address
        </p>
        <v-text-field
          outlined
          required
          dense
          class="can-disable-selector"
          :placeholder="disabledEdit ? '' : 'ที่อยู่'"
          v-model="addressVal"
          :disabled="disabledEdit"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'Information',
  props: {
    disabledEdit: {type: Boolean},
    isEditMode: {type: Boolean},
    name: {type: String},
    address: {type: String},
    lang: {type: String}
  },
  data() {
    return {
      errorsOrg: [],
      nameRules: [
        v => {
          // !!v || 'National ID/Passport is required'
          if (v != '') {
            return true
          }
          return 'Branch Name is required'
        }
      ],
    }
  },
  methods: {
    ...mapActions('organization', [
      'validateName',
    ])
  },
  watch: {
    async nameVal() {
      const type = this.lang === 'en' ? 'name' : `name_${this.lang}`
      let status = await this.validateName({
        type,
        input: this.nameVal,
        codename: this.isEditMode ? this.organizationObject.codename : ''
      })
      if (status) return (this.errorsOrg = [])
      return (this.errorsOrg = ['Branch Name Already Exists'])
    }
  },
  computed: {
    nameVal: {
      get() {
        return this.name
      },
      set(value) {
        this.$emit('changeName', value)
      }
    },
    addressVal: {
      get() {
        return this.address
      },
      set(value) {
        this.$emit('changeAddress', value)
      }
    },
    ...mapState('organization', [
      'organizationObject',
    ])
  }
}
</script>

<style scoped></style>