<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="450">
      <v-card>
        <v-card-title class="headline">
          <span t-data="headline" class="orange--text">Confirmation</span>
          <v-icon t-data="headline-icon" color="orange">information</v-icon>
        </v-card-title>
        <v-card-text t-data="card-text">
          If you <span v-bind:style="{color: 'red'}"><u>remove</u></span> this role,<br> Users will not be allowed to access to portal with this role <br>
          <b><u> Are you sure you want to remove? </u></b>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn t-data="remove-btn" color="red darken-1" text @click="comfirmPopup">Remove</v-btn>
          <v-btn t-data="cancel-btn" color="black darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'AlertConfirm',
    props: {
      show: {type: Boolean},
      close: {type: Function},
      confirm: {type: Function},
    },
    methods: {
      closePopup() {
        this.close()
      },
      comfirmPopup() {
        this.confirm()
      }
    }
  }
</script>

<style scoped>
</style>
